import { PropsWithChildren } from 'react';
import { Footer } from './Footer';
import { Container } from './Container';

export const Layout = ({children}: PropsWithChildren<{}>) => {
  return (
    <div className='flex flex-col justify-between h-full'>
      {children}
      <Container className={"py-10"} noVerticalMargin>
        <div className='col-span-12'>
        <Footer />
        </div>
      </Container>
    </div>
  )
}
