import { Heading, Paragraph, Spinner } from '@contentful/f36-components';
import React, { FC, useEffect, useState } from 'react';
import {spacesService} from "../services/spaces";

enum State {
  Loading,
  Success,
  Error,
}

const Error: FC = () => {
  return (
    <>
      <Heading>Something went wrong ☹️</Heading>
      <Paragraph>Please close this tab and try again.</Paragraph>
      <Paragraph>
        If the problem persists, please contact the app developer.
      </Paragraph>
    </>
  );
};

const Success: FC = () => {
  return (
    <>
      <Heading>Success! 🎉</Heading>
      <Paragraph>You can close this tab</Paragraph>
    </>
  );
};

const Loading: FC = () => <Spinner variant='primary' size='large' />;

const OauthCallback: FC = () => {
  const [state, setState] = useState<State>(State.Loading);

  useEffect(() => {
    const params = new URLSearchParams(
      window.location.search + window.location.hash.toString().replace('#', '&')
    );
    console.log(params);
    const oauthData = params.get('oauth_data');
    const accessToken = params.get('access_token');

    if (!oauthData || !accessToken) return

    const parsedOauthData = JSON.parse(oauthData);
    const backendAccessToken = parsedOauthData["backend_access_token"];
    const spaceId = parsedOauthData["space_id"];

    localStorage.setItem('accessToken', backendAccessToken);

    spacesService.update(spaceId, {
      managementToken: accessToken,
    })
      .then(() => {
        setState(State.Success);
      })
      .catch((err) => {
        console.error('Error updating space: ', err);
        setState(State.Error);
      });
  }, []);

  return (
    <div className='bg-[#c6dff4] h-full'>
      <div
        className={'flex flex-col items-center justify-center pt-[64px]'}
      >
        {state === State.Loading && <Loading />}
        {state === State.Success && <Success />}
        {state === State.Error && <Error />}
      </div>
    </div>
  );
};

export default OauthCallback;