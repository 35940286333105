import { FormProvider, useForm } from 'react-hook-form';
import { JobConfigurationForm, SpaceForm, spaceFormSchema } from '../lib/jobConfigurationFormSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, PropsWithChildren } from 'react';

const DEFAULT_JOB_CONFIGURATION: JobConfigurationForm = {
  day: 0,
  hour: 0,
  deliveryToken: '',
  environmentId: '',
  includeDrafts: false,
  includeArchived: false,
  enabled: false
}

const DEFAULT_SPACE_FORM: SpaceForm = {
  jobConfiguration: DEFAULT_JOB_CONFIGURATION,
  contactEmail: null
}

export const SpaceFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const form = useForm<SpaceForm>({
    defaultValues: DEFAULT_SPACE_FORM,
    reValidateMode: 'onChange',
    resolver: zodResolver(spaceFormSchema)
  });

  return (
    <FormProvider {...form}>
      {children}
    </FormProvider>
  );
}
