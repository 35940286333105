import {ctfAppClientId, ctfAppRedirectUri} from "./constants";


const querify = (object: Record<string, string>): string => Object.keys(object).map(key => `${key}=${object[key]}`).join('&');

const baseParams = {
  response_type: 'token',
  scope: 'content_management_manage',
  client_id: ctfAppClientId,
  redirect_uri: ctfAppRedirectUri,
}

const getParams = (redirectParams: Record<string, string>) => ({
  ...baseParams,
  redirect_uri: `${baseParams.redirect_uri}?${querify(redirectParams)}`
})

const ctfOauthBaseUrl = 'https://be.contentful.com/oauth/authorize';

// It seems redirectParams doesn't work well with more than one parameter...
export const getOauthUrl = (redirectParams: Record<string, string>) => `${ctfOauthBaseUrl}?${querify(getParams(redirectParams))}`;