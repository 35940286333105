import React, {FC, useContext, useEffect, useState} from "react";
import {Caption} from "@contentful/f36-components";
import {JobTable} from "../JobTable";
import {RightPanel} from "../RightPanel";
import {useFormContext} from "react-hook-form";
import {
  BeaJobsLimitInfoResponse,
  DeliveryTokensResponse,
  EnvironmentResponse,
  JobResponse,
  spacesService
} from "../../services/spaces";
import {ConfigAppSDK} from "@contentful/app-sdk";
import {useSDK} from "@contentful/react-apps-toolkit";
import { SpaceForm } from '../../lib/jobConfigurationFormSchema';
import {ConfigScreenStateContext, Screen} from "../../contexts/ConfigScreenState.context";
import {EbaHeading} from "../EbaHeading";
import {Container} from "../Container";
import { Layout } from '../Layout';

interface Props {}

export const MainScreen: FC<Props> = () => {
  const sdk = useSDK<ConfigAppSDK>();
  const {reset}  = useFormContext<SpaceForm>();

  const [environments, setEnvironments] = useState<EnvironmentResponse[]>([])
  const [deliveryTokens, setDeliveryTokens] = useState<DeliveryTokensResponse[]>([])
  const [jobs, setJobs] = useState<JobResponse[]>()
  const [jobsLimitInfo, setJobsLimitInfo] = useState<BeaJobsLimitInfoResponse>()
  const {state, dispatch} = useContext(ConfigScreenStateContext)
  const space = state.space

  useEffect(() => {
    console.log("spaceResponse", space)
    if (space?.jobConfiguration) {
      reset(space)
    }
  }, [space, reset]);

  useEffect(() => {
    spacesService.getEnvironments(sdk.ids.space)
      .then(res => {
        setEnvironments(res.data)
      })
      .catch(err => {
        console.error(err);
        if (err.response.status === 401) {
          dispatch({type: 'SET_SCREEN', payload: Screen.ManagementToken})
        }
        else {
          dispatch({type: 'SET_SCREEN', payload: Screen.ErrorScreen})
        }
      })

    spacesService.getDeliveryTokens(sdk.ids.space)
      .then(res => {
        setDeliveryTokens(res.data)
      })
      .catch(err => {
        console.error(err);
        if (err.response.status === 401) {
          dispatch({type: 'SET_SCREEN', payload: Screen.ManagementToken})
        }
        else {
          dispatch({type: 'SET_SCREEN', payload: Screen.ErrorScreen})
        }
      })

    const closeSubscription = spacesService.subscribeToJobs(sdk.ids.space, (res) => {
      setJobs(res.data.jobs)
      setJobsLimitInfo(res.data.limitInfo)
    })

    return () => {
      closeSubscription()
    }
  }, [sdk.ids.space, dispatch]);

  return (
    <Layout>
      <Container>
        <div className={'col-span-7'}>
          <div className={"mb-4 flex flex-row items-center justify-between"}>
            <EbaHeading />
            {jobsLimitInfo && (
              <Caption>
                {jobsLimitInfo.remainingJobs} / {jobsLimitInfo.limitPerMonth} jobs remaining this month
              </Caption>
            )}
          </div>
          <JobTable jobs={jobs || []} state={jobs ? "SUCCESS" : 'LOADING'} />
        </div>
        <div className={'col-span-5 -mt-[15px]'}>
          <RightPanel environments={environments} deliveryTokens={deliveryTokens} />
        </div>
      </Container>
    </Layout>
  )
}
