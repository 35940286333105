import {createContext, FC, PropsWithChildren, useReducer} from "react";
import {SpaceResponse} from "../services/spaces";

export enum Screen {
  Loading,
  Install,
  ManagementToken,
  MainScreen,
  ErrorScreen
}

interface ConfigScreenState {
  screen: Screen;
  space?: SpaceResponse
  backendAccessToken?: string
}

const initialState: ConfigScreenState = {
  screen: Screen.Loading,
  space: undefined,
  backendAccessToken: undefined
}

interface SetScreenAction {
  type: 'SET_SCREEN';
  payload: Screen;
}

interface SetSpaceAction {
  type: 'SET_SPACE';
  payload: SpaceResponse;
}

interface SetBackendAccessTokenAction {
  type: 'SET_BACKEND_ACCESS_TOKEN';
  payload: string;
}

type Action = SetScreenAction | SetSpaceAction | SetBackendAccessTokenAction

const reducer = (state: ConfigScreenState, action: Action) => {
  switch (action.type) {
    case 'SET_SCREEN':
      console.log("Setting screen to", action.payload)
      return {
        ...state,
        screen: action.payload
      }
    case 'SET_SPACE':
      return {
        ...state,
        space: action.payload
      }
    case 'SET_BACKEND_ACCESS_TOKEN':
      return {
        ...state,
        backendAccessToken: action.payload
      }
    default:
      return state
  }
}

interface ConfigScreenStateContextType {
  state: ConfigScreenState;
  dispatch: (action: Action) => void;
}

export const ConfigScreenStateContext = createContext<ConfigScreenStateContextType>({
  state: initialState,
  dispatch: () => {}
})

export const ConfigScreenStateProvider: FC<PropsWithChildren> = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ConfigScreenStateContext.Provider value={{state, dispatch}}>
      {children}
    </ConfigScreenStateContext.Provider>
  )
}