import { Form, FormControl, TextInput } from '@contentful/f36-components';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { SpaceForm } from '../lib/jobConfigurationFormSchema';

interface Props {
}

export const GeneralConfigurationForm: FC<Props> = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SpaceForm>();

  return (
    <Form className={'mt-5'}>
      <FormControl>
        <FormControl.Label>
          Contact Email
        </FormControl.Label>
        <TextInput
          type="email"
          placeholder="your-email@domain.com"
          {...register('contactEmail', {
            setValueAs: (value) => value === '' ? null : value,
          })}
        />
        <FormControl.HelpText>
          Just one contact email per space
        </FormControl.HelpText>
        {errors.contactEmail && <FormControl.ValidationMessage>{errors.contactEmail.message}</FormControl.ValidationMessage>}
      </FormControl>
    </Form>
  )
}
