import {Tabs} from "@contentful/f36-components";
import {JobConfiguration} from "./JobConfigurationForm";
import {Faq} from "./Faq";
import {StorageConfiguration} from "./StorageConfiguration";
import React, {FC} from "react";
import {DeliveryTokensResponse, EnvironmentResponse} from "../services/spaces";
import { GeneralConfigurationForm } from './GeneralConfigurationForm';

interface Props {
  environments: EnvironmentResponse[];
  deliveryTokens: DeliveryTokensResponse[];
}

export const RightPanel: FC<Props> = ({environments, deliveryTokens}) => {
  return (
    <Tabs defaultTab={"0"}>
      <Tabs.List>
        <Tabs.Tab panelId="0">Jobs</Tabs.Tab>
        <Tabs.Tab panelId={"1"}>General</Tabs.Tab>
        <Tabs.Tab panelId="2">FAQ</Tabs.Tab>
        { /* <Tabs.Tab panelId="third">Storage Configuration</Tabs.Tab> */ }
      </Tabs.List>
      <Tabs.Panel id="0">
        <JobConfiguration environments={environments} deliveryTokens={deliveryTokens} />
      </Tabs.Panel>
      <Tabs.Panel id="1">
        <GeneralConfigurationForm />
      </Tabs.Panel>
      <Tabs.Panel id="2">
        <Faq />
      </Tabs.Panel>
      <Tabs.Panel id="3">
        <StorageConfiguration />
      </Tabs.Panel>
    </Tabs>
  )
}
