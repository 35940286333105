import axios, {AxiosInstance} from "axios";
import {CMAClient} from "@contentful/app-sdk";

interface LoginResponse {
  accessToken: string;
}

class AuthService {
  constructor(private axios: AxiosInstance) {}

  async login(cma: CMAClient, appDefinitionId: string) {
    const payload = {
      method: 'POST' as const,
      headers: {},
      body: '{}',
      path: '/auth/login',
    }

    const { additionalHeaders } = await cma.appSignedRequest.create({
      appDefinitionId
    }, payload);

    return await this.axios.post<LoginResponse>("/auth/login", {}, {
      headers: {
        ...additionalHeaders,
      },
    })
  }
}

export const authService = new AuthService(axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})) ;