import {z} from "zod";

export const jobConfigurationFormSchema = z.object({
  enabled: z.boolean(),
  deliveryToken: z.string().nullable(),
  environmentId: z.string().min(1),
  includeDrafts: z.boolean(),
  includeArchived: z.boolean(),
  day: z.number(),
  hour: z.number()
})

export const spaceFormSchema = z.object({
  jobConfiguration: jobConfigurationFormSchema,
  contactEmail: z.string().email().nullable()
})

export type JobConfigurationForm = z.infer<typeof jobConfigurationFormSchema>;
export type SpaceForm = z.infer<typeof spaceFormSchema>;
