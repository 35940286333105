import {Paragraph} from "@contentful/f36-components";
import {EbaHeading} from "../EbaHeading";
import {Container} from "../Container";
import { ContactEmail } from '../ContactEmail';
import { Layout } from '../Layout';

export const ErrorScreen = () => {
  return (
    <Layout>
      <Container>
        <div className={'col-span-7'}>
          <EbaHeading className={'mb-3'} />
          <Paragraph>
           It seems that something went wrong. Please try refreshing the page or
            contact with <ContactEmail /> for help.
          </Paragraph>
        </div>
      </Container>
    </Layout>
  )
}
