import { GlobalStyles } from '@contentful/f36-components';
import { SDKProvider } from '@contentful/react-apps-toolkit';
import './index.css';

import { createRoot } from 'react-dom/client';
import LocalhostWarning from './components/LocalhostWarning';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ContentfulApp from "./routes/ContentfulApp";
import OauthCallback from "./routes/OauthCallback";
import {ConfigScreenStateProvider} from "./contexts/ConfigScreenState.context";
import { SpaceFormProvider } from './providers/SpaceForm.provider';
import { Index } from './routes/Index';

const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
  },
  {
    path: '/oauth-callback',
    element: <OauthCallback />,
  },
  {
    path: '/contentful-app',
    element: (
      <>
        {process.env.NODE_ENV === 'development' &&
        window.self === window.top ? (
          <LocalhostWarning />
        ) : (
          <SDKProvider>
            <ConfigScreenStateProvider>
            <SpaceFormProvider>
              <ContentfulApp />
            </SpaceFormProvider>
            </ConfigScreenStateProvider>
          </SDKProvider>
        )}
      </>
    ),
  },
]);

root.render(
  <>
    <GlobalStyles />
    <RouterProvider router={router} />
  </>
);
