import { Checkbox, DateTime, Form, FormControl, Paragraph, Select, Switch } from '@contentful/f36-components';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DeliveryTokensResponse, EnvironmentResponse } from '../services/spaces';
import { SpaceForm } from '../lib/jobConfigurationFormSchema';

interface Props {
  environments: EnvironmentResponse[];
  deliveryTokens: DeliveryTokensResponse[];
}

const formatUTCHour = (hour: number) => {
  const date = new Date();
  date.setUTCHours(hour);
  date.setUTCMinutes(0);
  date.setSeconds(0);

  return date.toLocaleTimeString(Intl.DateTimeFormat().resolvedOptions().locale, {
    hour: '2-digit',
    hour12: true,
    minute: 'numeric'
  });
}

const nextJobDate = (hour: number, day: number) => {
  const date = new Date();
  date.setUTCHours(hour);
  date.setUTCMinutes(0);
  date.setSeconds(0);
  date.setDate(date.getDate() + (day - date.getDay() + 7) % 7);

  return date;
}

const hours = Array.from({length: 24}, (_, i) => i);

export const JobConfiguration: FC<Props> = ({environments, deliveryTokens}) => {
  const {
    register,
    formState: { errors },
    control,
    watch
  } = useFormContext<SpaceForm>();

  const enabled = watch('jobConfiguration.enabled');
  const hour = watch('jobConfiguration.hour');
  const day = watch('jobConfiguration.day');

  return (
    <Form className={'mt-5'}>
      <FormControl>
        <Controller
          name={'jobConfiguration.enabled'}
          control={control}
          render={({field}) => (
            <Switch
              {...field}
              isChecked={field.value}
              value={field.value ? 'true' : 'false'}
            >
              {enabled ? 'Jobs are enabled 🚀' : 'Jobs are disabled'}
            </Switch>
          )}
        />
      </FormControl>
      <FormControl isRequired>
        <FormControl.Label>Day</FormControl.Label>
        <Select {...register("jobConfiguration.day", { valueAsNumber: true })}>
          <option value={1}>Monday</option>
          <option value={2}>Tuesday</option>
          <option value={3}>Wednesday</option>
          <option value={4}>Thursday</option>
          <option value={5}>Friday</option>
          <option value={6}>Saturday</option>
          <option value={0}>Sunday</option>
        </Select>
        {errors.jobConfiguration?.day && (
          <FormControl.ValidationMessage>
            This field is required
          </FormControl.ValidationMessage>
        )}
      </FormControl>
      <FormControl isRequired>
        <FormControl.Label>Hour</FormControl.Label>
        <Select {...register("jobConfiguration.hour", { valueAsNumber: true })}>
          {hours.map((hour) => (
            <option key={hour} value={hour}>
              {formatUTCHour(hour)}
            </option>
          ))}
        </Select>
        {errors.jobConfiguration?.hour && (
          <FormControl.ValidationMessage>
            This field is required
          </FormControl.ValidationMessage>
        )}
      </FormControl>
      <FormControl isRequired>
        <FormControl.Label>Environment</FormControl.Label>
        <Select {...register("jobConfiguration.environmentId")}>
          {environments.map((env) => (
            <option key={env.id} value={env.id}>
              {env.name}
            </option>
          ))}
          <option value={''}></option>
        </Select>
        {errors.jobConfiguration?.environmentId && (
          <FormControl.ValidationMessage>
            This field is required
          </FormControl.ValidationMessage>
        )}
      </FormControl>
      <FormControl>
        <FormControl.Label>Delivery Token</FormControl.Label>
        <Select {...register("jobConfiguration.deliveryToken", {setValueAs: (value) => value === '' ? null : value})}>
          {deliveryTokens.map((key) => (
            <option key={key.accessToken} value={key.accessToken}>
              {key.name}
            </option>
          ))}
          <option value={''}></option>
        </Select>
      </FormControl>
      <FormControl>
        <Checkbox
          {...register("jobConfiguration.includeDrafts")}
        >
          Include drafts
        </Checkbox>
        <FormControl.HelpText>Include drafts in the exported entries</FormControl.HelpText>
      </FormControl>
      <FormControl>
        <Checkbox
          {...register("jobConfiguration.includeArchived")}
        >
          Include archived
        </Checkbox>
        <FormControl.HelpText>Include archived in the exported entries</FormControl.HelpText>
      </FormControl>
      <FormControl>
        <Checkbox
          isDisabled
        >
          Download assets
        </Checkbox>
      </FormControl>
      <Paragraph>
        Based on the configuration, the next job will run on <b><DateTime date={nextJobDate(hour, day)} /></b> ({Intl.DateTimeFormat().resolvedOptions().timeZone})
      </Paragraph>
    </Form>
  )
}
