import React, {FC, useContext} from "react";
import {Button, Form, Paragraph, Text} from "@contentful/f36-components";
import {spacesService} from "../../services/spaces";
import {ConfigAppSDK} from "@contentful/app-sdk";
import {useSDK} from "@contentful/react-apps-toolkit";
import {CycleIcon} from "@contentful/f36-icons";
import {getOauthUrl} from "../../lib/contentful";
import {ConfigScreenStateContext, Screen} from "../../contexts/ConfigScreenState.context";
import {EbaHeading} from "../EbaHeading";
import {Container} from "../Container";
import { Layout } from '../Layout';

interface Props {}

export const ManagementTokenScreen: FC<Props> = () => {
  const sdk = useSDK<ConfigAppSDK>();
  const {state, dispatch} = useContext(ConfigScreenStateContext)

  const onClickOauthButton = () => {
    spacesService.pollSpaceWithManagementToken(sdk.ids.space)
      .then(res => {
        dispatch({type: 'SET_SCREEN', payload: Screen.MainScreen})
        dispatch({type: 'SET_SPACE', payload: res.data})
        console.log("Polling space with management token successful")
      })
      .catch(err => {
        console.log("Polling space with management token failed")
        console.error(err);
        dispatch({type: 'SET_SCREEN', payload: Screen.ErrorScreen})
      })
  }

  return (
    <Layout>
      <Container>
        <div className={'col-span-7'}>
          <EbaHeading className={'mb-3'} />
          <Form>
            <Paragraph>
              Hi {sdk.user.firstName}!, please connect your account by clicking
              the <Text fontWeight='fontWeightDemiBold'>Connect space</Text>{' '}
              button so that we can generate a management API and perform
              backups in the background.
            </Paragraph>
            <Paragraph>
              This step is essential to enable the app to seamlessly interact
              with your Contentful account and carry out its intended
              functionalities 🚀.
            </Paragraph>
            {state.backendAccessToken && <Button
              startIcon={
                <div style={{ transform: 'translateY(-1px)' }}>
                  <CycleIcon />
                </div>
              }
              variant='primary'
              as='a'
              onClick={onClickOauthButton}
              href={getOauthUrl({ oauth_data: JSON.stringify({space_id: sdk.ids.space, backend_access_token: state.backendAccessToken}) })}
              target='_blank'
            >
              Connect space
            </Button> }
          </Form>
        </div>
      </Container>
    </Layout>
  )
}
