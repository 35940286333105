import { TextLink } from '@contentful/f36-components';
import { FC } from 'react';
import { ExternalLinkIcon } from '@contentful/f36-icons';

interface Props {
  variant?: 'contact' | 'email'
}

export const ContactEmail: FC<Props> = ({variant = 'email'}) => {
  return <TextLink
    icon={<ExternalLinkIcon />}
    alignIcon={'end'}
    variant='secondary'
    type={'external'}
    href={'mailto:eba@omargarrios.dev'}>
      {variant === 'email' ?  'eba@omargarrios.dev' : 'Contact'}
  </TextLink>
}
