import {Spinner} from "@contentful/f36-components";

export const LoadingScreen = () => {
  return (
    <div className='h-full'>
      <div
        className={'flex flex-col items-center justify-center pt-[64px]'}
      >
        <Spinner variant='primary' size='large' />
      </div>
    </div>
  )
}