import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ConfigAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { authService } from '../services/auth';
import { spacesService } from '../services/spaces';
import { useFormContext } from 'react-hook-form';
import { SpaceForm, spaceFormSchema } from '../lib/jobConfigurationFormSchema';
import { MainScreen } from '../components/screens/MainScreen';
import { ManagementTokenScreen } from '../components/screens/ManagementTokenScreen';
import { ConfigScreenStateContext, Screen } from '../contexts/ConfigScreenState.context';
import { ErrorScreen } from '../components/screens/ErrorScreen';
import { InstallScreen } from '../components/screens/InstallScreen';
import { LoadingScreen } from '../components/screens/LoadingScreen';

export interface AppInstallationParameters {}

const ConfigScreen = () => {
  const [parameters, setParameters] = useState<AppInstallationParameters>({});
  const sdk = useSDK<ConfigAppSDK>();
  const form = useFormContext<SpaceForm>();
  const {state, dispatch} = useContext(ConfigScreenStateContext)

  const [isInstalled, setIsInstalled] = useState<boolean>();

  const onConfigure = useCallback(async () => {
    // This method will be called when a user clicks on "Install"
    // or "Save" in the configuration screen.
    // for more details see https://www.contentful.com/developers/docs/extensibility/ui-extensions/sdk-reference/#register-an-app-configuration-hook
    if(isInstalled) {
      form.trigger()

      const formValues = form.getValues();

      const safeParse = spaceFormSchema.safeParse(form.getValues())
      if (!safeParse.success) {
        sdk.notifier.error('Invalid form values')
        console.error(safeParse.error)
        return false
      }

      spacesService.update(sdk.ids.space, {
        contactEmail: formValues.contactEmail,
        jobConfiguration: {
          environmentId: formValues.jobConfiguration.environmentId,
          includeDrafts: formValues.jobConfiguration.includeDrafts,
          includeArchived: formValues.jobConfiguration.includeArchived,
          day: formValues.jobConfiguration.day,
          hour: formValues.jobConfiguration.hour,
          deliveryToken: formValues.jobConfiguration.deliveryToken,
          enabled: formValues.jobConfiguration.enabled
        }
      })
        .then(res => {
          console.log("Updated job configuration")
          console.log(res.data)
        })
        .catch(err => {
          console.error(err);
        })
    }

    // Get current the state of EditorInterface and other entities
    // related to this app installation
    const currentState = await sdk.app.getCurrentState();

    return {
      // Parameters to be persisted as the app configuration.
      parameters: parameters,
      // In case you don't want to submit any update to app
      // locations, you can just pass the currentState as is
      targetState: currentState,
    };
  }, [parameters, sdk, form, isInstalled]);

  useEffect(() => {
    // `onConfigure` allows to configure a callback to be
    // invoked when a user attempts to install the app or update
    // its configuration.
    sdk.app.onConfigure(() => onConfigure());
  }, [sdk, onConfigure]);

  useEffect(() => {
    (async () => {
      // Get current parameters of the app.
      // If the app is not installed yet, `parameters` will be `null`.
      const currentParameters: AppInstallationParameters | null = await sdk.app.getParameters();

      if (currentParameters) {
        setParameters(currentParameters);
      }

      // Once preparation has finished, call `setReady` to hide
      // the loading screen and present the app to a user.
      sdk.app.setReady();
    })();
  }, [sdk]);

  useEffect(() => {
    sdk.app.isInstalled().then((installed) => {
      setIsInstalled(installed)
    })

    sdk.app.onConfigurationCompleted((err) => {
      if (err) {
        console.error(err);
        dispatch({type: 'SET_SCREEN', payload: Screen.ErrorScreen})
      }else {
        setIsInstalled(true)
      }
    })

    return () => {
      sdk.app.onConfigurationCompleted(() => {})
    }
  }, [sdk.app.isInstalled, dispatch, sdk.app])

  useEffect(() => {
    if (isInstalled === undefined) return
    if (isInstalled) {
      authService.login(sdk.cma, sdk.ids.app)
        .then(res => {
          localStorage.setItem('accessToken', res.data.accessToken);
          dispatch({type: 'SET_BACKEND_ACCESS_TOKEN', payload: res.data.accessToken})

          spacesService.findOne(sdk.ids.space)
            .then(res => {
              dispatch({type: 'SET_SPACE', payload: res.data})
              dispatch({type: 'SET_SCREEN', payload: Screen.MainScreen})
            })
            .catch(err => {
              console.error(err);
              spacesService.create({
                id: sdk.ids.space,
                organizationId: sdk.ids.organization,
              })
                .then(res => {
                  dispatch({type: 'SET_SPACE', payload: res.data})
                  dispatch({type: 'SET_SCREEN', payload: Screen.MainScreen})
                })
                .catch(err => {
                  console.error(err);
                  dispatch({type: 'SET_SCREEN', payload: Screen.ErrorScreen})
                })
            })
        })
        .catch(err => {
          console.error(err);
          dispatch({type: 'SET_SCREEN', payload: Screen.ErrorScreen})
        })
    } else {
      dispatch({type: 'SET_SCREEN', payload: Screen.Install})
    }
  }, [isInstalled, dispatch, sdk])

  return (
    <>
      {state.screen === Screen.Loading && <LoadingScreen />}
      {state.screen === Screen.ErrorScreen && <ErrorScreen />}
      {state.screen === Screen.Install && <InstallScreen />}
      {state.screen === Screen.MainScreen && <MainScreen /> }
      {state.screen === Screen.ManagementToken && <ManagementTokenScreen />}
    </>
  );
};

export default ConfigScreen;
