import {FC, PropsWithChildren} from "react";
import classNames from 'classnames';

interface Props {
  className?: string;
  noVerticalMargin?: boolean;
}

export const Container: FC<PropsWithChildren<Props>> = ({children, className, noVerticalMargin}) => {
  return (
    <div className={classNames('mx-[80px] gap-[80px] grid grid-cols-12 max-w-screen-2xl min-w-[1100px]', className, {
      'my-[80px]': !noVerticalMargin,
    })}>
      {children}
    </div>
  )
}
