import axios, {AxiosInstance, AxiosResponse} from "axios";

interface CreateBody {
  id: string;
  organizationId: string;
}

interface UpdateBody {
  contactEmail?: string | null;
  managementToken?: string;
  jobConfiguration?: {
    enabled: boolean;
    environmentId: string;
    includeDrafts: boolean;
    includeArchived: boolean;
    day: number;
    hour: number;
    deliveryToken: string | null;
  }
}

export interface JobResponse {
  id: string;
  status: 'RUNNING' | 'COMPLETED' | 'FAILED' | 'CANCELED'
  createdAt: string;
  updatedAt: string;
  retries: number;
  ctfExportConfig: {
    environmentId: string;
  }
}

export interface BeaJobsLimitInfoResponse {
  remainingJobs: number;
  limitPerMonth: number;
}

export interface GetJobsResponse {
  jobs: JobResponse[];
  limitInfo: BeaJobsLimitInfoResponse;
}

export interface SpaceResponse {
  managementToken: string;
  jobConfiguration?: {
    enabled: boolean;
    environmentId: string;
    includeDrafts: boolean;
    includeArchived: boolean;
    day: number;
    hour: number;
    deliveryToken: string;
  }
  jobs: JobResponse[];
}

export interface EnvironmentResponse {
  id: string;
  name: string;
}

type GetEnvironmentsResponse = EnvironmentResponse[];

export interface DeliveryTokensResponse {
  name: string;
  accessToken: string;
}

type GetDeliveryTokensResponse = DeliveryTokensResponse[];

class SpacesService {
  constructor(private axios: AxiosInstance) {
    this.axios.interceptors.request.use(async (config) => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    })
  }

  async create(createBody: CreateBody) {
    return await this.axios.post<SpaceResponse>("/spaces", createBody);
  }

  async findOne(spaceId: string) {
    return await this.axios.get<SpaceResponse>(`/spaces/${spaceId}`);
  }

  async update(spaceId: string, updateBody: UpdateBody) {
    return await this.axios.patch(`/spaces/${spaceId}`, updateBody);
  }

  async pollSpaceWithManagementToken(spaceId: string) {
    return new Promise<AxiosResponse<SpaceResponse>>((resolve) => {
      const id = setInterval(async () => {
        const res = await this.axios.get(`/spaces/${spaceId}`);
        if (res.data.managementToken) {
          clearInterval(id);
          resolve(res);
        }
      }, 3000);
    });
  }

  async getEnvironments(spaceId: string) {
    return await this.axios.get<GetEnvironmentsResponse>(`/spaces/${spaceId}/environments`);
  }

  async getDeliveryTokens(spaceId: string) {
    return await this.axios.get<GetDeliveryTokensResponse>(`/spaces/${spaceId}/delivery-tokens`);
  }

  subscribeToJobs(spaceId: string, onData: (data: AxiosResponse<GetJobsResponse>) => void): () => void {
    const eventTarget = new EventTarget()

    const callback = async () => {
      const res = await this.axios.get<GetJobsResponse>(`/spaces/${spaceId}/jobs`);
      eventTarget.dispatchEvent(new CustomEvent('jobs', { detail: res }))
    }

    // Initial call
    callback()

    const timerId = setInterval(callback, 5000)

    eventTarget.addEventListener('jobs', (e) => {
      onData((e as CustomEvent).detail)
    })

    return () => clearInterval(timerId)
  }

  async getBackupUrl(spaceId: string, jobId: string) {
    return await this.axios.get<string>(`/spaces/${spaceId}/jobs/${jobId}/backupUrl`);
  }
}

export const spacesService = new SpacesService(axios.create({
  baseURL: process.env.REACT_APP_API_URL,
}));
