import {
  Badge,
  Modal,
  Pagination,
  RelativeDateTime,
  Spinner,
  Table,
  TextLink, Tooltip,
} from "@contentful/f36-components";
import React, {FC, useState} from "react";
import { ArchiveIcon, InfoCircleIcon } from '@contentful/f36-icons';
import {JobResponse, spacesService} from "../services/spaces";
import {useSDK} from "@contentful/react-apps-toolkit";
import {ConfigAppSDK} from "@contentful/app-sdk";
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const PAGE_SIZE = 10;
const CANCELED_REASON = "You may have no remaining jobs left. Or the job configuration is disabled.";
const FAILED_REASON = "The job failed. Please contact support.";

interface Props {
  jobs: JobResponse[];
  state: 'LOADING' | 'SUCCESS'
}

export const JobTable: FC<Props> = ({jobs, state}) => {
  const sdk = useSDK<ConfigAppSDK>();
  const [currentPage, setCurrentPage] = useState(0);
  const revertedJobs = jobs.slice().reverse();
  const slicedJobs = revertedJobs.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

  const [isShown, setShown] = useState(false);
  const [json, setJson] = useState({});

  return (
    <>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Job Id</Table.Cell>
            <Table.Cell>Environment Id</Table.Cell>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell>Retries</Table.Cell>
            <Table.Cell>Date</Table.Cell>
            <Table.Cell>Config</Table.Cell>
            <Table.Cell>Download</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {slicedJobs.map((job) => (
            <Table.Row key={job.id}>
              <Table.Cell>{job.id}</Table.Cell>
              <Table.Cell>{job.ctfExportConfig.environmentId}</Table.Cell>
              <Table.Cell>
                {job.status === "COMPLETED" && <Badge variant={"positive"}>{job.status}</Badge> }
                {job.status === "RUNNING" && <Badge variant={"warning"}>{job.status}</Badge> }
                {job.status === "CANCELED" && (
                  <Tooltip placement="bottom" id={"cancelled-info"} content={CANCELED_REASON}>
                    <Badge variant={"secondary"}>{job.status}<InfoCircleIcon className={"ml-1"} variant={'secondary'} /></Badge>
                  </Tooltip>
                )}
                {job.status === "FAILED" && (
                  <Tooltip placement="bottom" id={"cancelled-info"} content={FAILED_REASON}>
                    <Badge variant={"negative"}>{job.status}<InfoCircleIcon className={"ml-1"} variant={'negative'} /></Badge>
                  </Tooltip>
                )}
              </Table.Cell>
              <Table.Cell>{job.retries}</Table.Cell>
              <Table.Cell><RelativeDateTime date={job.updatedAt} /></Table.Cell>
              <Table.Cell>
                <TextLink
                  onClick={() => {
                      setJson(job.ctfExportConfig);
                      setShown(true);
                  }}
                >
                  View
                </TextLink>
              </Table.Cell>
              <Table.Cell align={"center"}>
                {job.status === "COMPLETED" && (<TextLink
                  onClick={() => {
                    spacesService.getBackupUrl(sdk.ids.space, job.id).then((res) => {
                      window.open(res.data, '_blank')
                    })
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ArchiveIcon />
                </TextLink>)}
              </Table.Cell>
            </Table.Row>
          ))}
          {jobs.length === 0 && state === "SUCCESS" && <Table.Row>
            <Table.Cell align={"center"} colSpan={7}>No jobs found</Table.Cell>
          </Table.Row>}
          {state === "LOADING" && <Table.Row>
            <Table.Cell align={"center"} colSpan={7}>
                <Spinner size={"small"} />
            </Table.Cell>
          </Table.Row>}
        </Table.Body>
      </Table>
      {jobs.length > PAGE_SIZE && <Pagination
        className={'mt-[40px]'}
        activePage={currentPage}
        onPageChange={setCurrentPage}
        itemsPerPage={PAGE_SIZE}
        totalItems={jobs.length}
      /> }
      <Modal onClose={() => setShown(false)} isShown={isShown}>
        {() => (
          <>
            <Modal.Header
              title="Contentful export config"
              onClose={() => setShown(false)}
            />
            <Modal.Content>
              <JsonView data={json} shouldExpandNode={allExpanded} style={defaultStyles} />
            </Modal.Content>
          </>
        )}
      </Modal>
    </>
  )
}