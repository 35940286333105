import {Note} from "@contentful/f36-components";
import React from "react";

export const StorageConfiguration = () => {
  return (
    <div className={'mt-5 flex flex-col'}>
      <Note variant="neutral">
        Only cloud storage is supported at the moment. Custom storage will be available soon.
      </Note>
    </div>
  )
}