import {FC} from "react";
import {Heading} from "@contentful/f36-components";

interface Props {
  className?: string
}

export const EbaHeading: FC<Props> = ({className}) => {
  return (
    <div className={className}>
      <Heading marginBottom={'none'}>Export Backups App</Heading>
    </div>
  )
}
