import { Accordion, Text, TextLink } from '@contentful/f36-components';
import React from 'react';
import { ContactEmail } from './ContactEmail';

export const Faq = () => {
  return (
    <div className={'mt-5'}>
      <Accordion>
        <Accordion.Item title="How it works?">
          <Text as={'p'} marginBottom={'spacingS'}>
            We use the <TextLink target={"_blank"} href={"https://github.com/contentful/contentful-export"}>contentful-export</TextLink> to fetch your data and store it in a secure location.
          </Text>
          <Text as={'p'}>
            Once is backed up, you can download it at any time. It is important to note that you are responsible to restore
            it with <TextLink target={"_blank"} href={"https://github.com/contentful/contentful-import"}>contentful-import</TextLink>.
          </Text>
        </Accordion.Item>
        <Accordion.Item title="Where is my data stored?">
          <Text>
            Your data is stored in an encrypted S3 bucket. We plan to enable users to utilize their own buckets (AWS/Azure/Google Cloud) at some point.
          </Text>
        </Accordion.Item>
        <Accordion.Item title="Is it free of charge?">
          <Text>
            Yes, it is!
          </Text>
        </Accordion.Item>
        <Accordion.Item title="Are my images/videos backed up?">
          <Text>
            Currently, due to server limitations, we do not store assets. However, this may change in the future.
          </Text>
        </Accordion.Item>
        <Accordion.Item title="How can I delete my data?">
          <Text>
            To delete your data, please send an email to <ContactEmail />. Your backups or entire account will be removed within 78 hours.
          </Text>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
