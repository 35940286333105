import { ContactEmail } from './ContactEmail';
import { Paragraph } from '@contentful/f36-components';
import { FC } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const currentYear = new Date().getFullYear();

export const Footer: FC<Props> = ({className}) => {
  return (
    <footer className={classNames('flex flex-row justify-center w-full gap-5', className)}>
      <Paragraph marginBottom={'none'}>
        © {currentYear} Export Backups App
      </Paragraph>
      <ContactEmail variant={'contact'} />
    </footer>
  )
}
