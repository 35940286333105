import {Paragraph} from "@contentful/f36-components";
import {useSDK} from "@contentful/react-apps-toolkit";
import {ConfigAppSDK} from "@contentful/app-sdk";
import {ArrowUpwardIcon} from "@contentful/f36-icons";
import {EbaHeading} from "../EbaHeading";
import {Container} from "../Container";
import { Layout } from '../Layout';

export const InstallScreen = () => {
  const sdk = useSDK<ConfigAppSDK>();

  return (
    <Layout>
      <Container>
        <div className={'col-span-7'}>
          <EbaHeading className={'mb-3'} />
          <Paragraph>
            Hi {sdk.user.firstName}!, please install the app first to continue.
          </Paragraph>
          <div className={'absolute right-10 top-[10px] arrow-upward-install'}>
            <ArrowUpwardIcon size="large" />
          </div>
        </div>
      </Container>
    </Layout>
  )
}
